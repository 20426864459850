import __request__ from 'routing/request';
interface EventProcedure {
    readonly description: string;
    readonly id: string;
    readonly title: string;
    readonly steps: EventProcedureStep[];
}
interface ActionConfig {
    readonly type: string;
}
interface PaginatedResponse_EventProcedureStep {
    readonly [_key: string]: Object;
}
interface PaginatedResponse_EventProcedure {
    readonly [_key: string]: Object;
}
interface EventProcedureStep {
    readonly action: ActionDto;
    readonly description: string;
    readonly id: string;
    readonly title: string;
}
interface ActionDto {
    readonly title: string;
    readonly config: ActionConfig;
}
/**
 * Get a paginated list of event procedures
 * @param sort The field to sort the result on
 * @param direction The sort direction
 */
export function listProcedures(sort: 'title' | 'description' = 'title', page: number = 1, per_page: number = 15, query?: string, filters?: string, direction: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_EventProcedure> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/event_procedures', null, { 'page': page, 'per_page': per_page, 'query': query, 'filters': filters, 'sort': sort, 'direction': direction }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new event procedure
 */
export function createProcedure(JSONBody?: EventProcedure): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/event_procedures', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a paginated list of event procedure steps
 * @param sort The field to sort the result on
 * @param direction The sort direction
 */
export function listSteps(sort: 'title' | 'description' = 'title', page: number = 1, per_page: number = 15, query?: string, filters?: string, direction: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_EventProcedureStep> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/event_procedures/steps', null, { 'page': page, 'per_page': per_page, 'query': query, 'filters': filters, 'sort': sort, 'direction': direction }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new event procedure step
 */
export function createStep(JSONBody?: EventProcedureStep): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/event_procedures/steps', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Edit an existing event procedure step
 */
export function editStep(id: string, JSONBody?: EventProcedureStep): Promise<void> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/event_procedures/steps/${id}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an existing procedure step
 */
export function deleteStep(id: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.securityapp/event_procedures/steps/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Edit an existing event procedure
 */
export function editProcedure(id: string, JSONBody?: EventProcedure): Promise<void> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/event_procedures/${id}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an existing procedure
 */
export function deleteProcedure(id: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.securityapp/event_procedures/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
