import * as React from 'react';
import styled, { css } from 'styled-components';

import type { Stream } from 'stores/streams/StreamsStore';
import { Icon, Tooltip } from 'components/common';
import { LinkContainer } from 'components/common/router';
import { Button } from 'components/bootstrap';
import DataWarehouseRoutes from 'data-warehouse/Routes';

import useStreamDataWarehouseStatus from '../hooks/useStreamDataWarehouseStatus';

const Wrapper = styled.div(({ theme }) => css`
  color: ${theme.colors.variant.default};
  width: fit-content;
`);

type Props = {
  stream: Stream
}

const DataWareHouseCell = ({ stream }: Props) => {
  const { data: config, isFetching } = useStreamDataWarehouseStatus(stream.id);

  if (!stream.is_editable || isFetching) {
    return null;
  }

  const isDataWareHouseEnabled = config?.enabled || false;

  if (isDataWareHouseEnabled) {
    return (
      <LinkContainer to={`${DataWarehouseRoutes.SEARCH}?streams=${stream.id}`}>
        <Button bsSize="xs" title="Search in warehouse">
          <Icon name="search" /> Preview logs
        </Button>
      </LinkContainer>
    );
  }

  return (
    <Tooltip withArrow position="right" label="Data Warehouse is disabled">
      <Wrapper>
        <Icon name="cancel" />
      </Wrapper>
    </Tooltip>
  );
};

export default DataWareHouseCell;
